import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from "./auth/login/login.component";
import { NotfoundComponent } from './components/common/notfound/notfound.component';
import { DeniedComponent } from './components/common/denied/denied.component';
import { NewebpayApplyComponent } from './components/newebpay/apply/newebpay.apply.component';
import { NewebpayInfoComponent } from './components/newebpay/info/newebpay.info.component';
import { LoginGuard } from "./auth/login.guard";
import { AuthGuard } from './auth/auth.guard';
import { NewebpayGuard } from './auth/newebpay.guard';
import { RedirectGuard } from './auth/redirect.guard';
import { OnlineContractComponent } from './components/online-contract/online-contract.component';

const routes: Routes = [
    { path: 'login', canActivate: [LoginGuard], component: LoginComponent },
    { path: '404', component: NotfoundComponent },
    { path: 'denied', component: DeniedComponent },
    {
        path: 'line',
        children: [
            // {
            //     path: 'marketplace',
            //     component: AuthorizeComponent
            // },
            // {
            //     path: 'phone',
            //     loadChildren: () => import('./components/line/auth/phone-auth.module').then(m => m.PhoneAuthModule)
            // },
            {
                path: 'lite',
                loadChildren: () => import('./components/line/lite/line-lite.module').then(m => m.LineLiteModule)

                // children: [
                //     {
                //         path: 'auth',
                //         component: LiteAuthComponent
                //     },
                //     {
                //         path: 'login',
                //         component: LiteLoginComponent
                //     }
                // ]
            },
        ]
    },
    {
        path: 'newebpay',
        canActivate: [NewebpayGuard],
        children: [
            {
                path: 'apply',
                component: NewebpayApplyComponent
            },
            {
                path: 'info',
                component: NewebpayInfoComponent
            }
        ]
    },
    {
        path: 'online-contract/:id',
        canActivate: [RedirectGuard],
        component: OnlineContractComponent
    },
    { path: 'rating/fill/:code', loadChildren: () => import('./components/rating/fill-rating.module').then(m => m.FillRatingModule) },
    // { path: '', canActivate: [AuthGuard], component: IndexComponent },
    // {
    //     path: 'branch/:id',
    //     loadChildren: () => import('./components/branch/branch.module').then(m => m.BranchModule)
    // },
    {
        path: 'management',
        loadChildren: () => import('./components/management/management.module').then(m => m.ManagementModule)
    },
    {
        path: 'group',
        loadChildren: () => import('./components/group/group.module').then(m => m.GroupModule)

    },
    {
        path: '', canActivate: [AuthGuard], redirectTo: "management",
        pathMatch: "full"
    },
    {
        "path": "**",
        "redirectTo": "404",
        "pathMatch": "full"
    },

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
